import {
  StyledHeader,
  RightContainer,
  StyledLink,
} from '@/components/Header/styles-v1';
import { useState } from 'react';
import MenuDrawerV1 from '@/components/MenuDrawer/MenuDrawerV1';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import Profile from '@/components/Profile';
import { FnCoCompanyCode } from '@/utils/const';

const HeaderV1 = () => {
  const { companyCode } = useRecoilValue(MyInfoState);
  const isFnCo = FnCoCompanyCode.includes(companyCode);

  const router = useRouter();
  const showMenu = router.route === '/' || router.pathname.startsWith('/board');

  const [open, setOpen] = useState(false);
  const handleOnClick = () => setOpen((prev) => !prev);
  const handleClose = () => setOpen(false);

  const handleRefresh = () => {
    router.route !== '/' ? router.push('/') : router.reload();
  };

  return (
    <StyledHeader
      open={open}
      className={`${open ? 'open' : ''}`}
      isFnCo={isFnCo}
    >
      {showMenu && (
        <MenuDrawerV1
          open={open}
          handleOnClick={handleOnClick}
          handleClose={handleClose}
        />
      )}
      <StyledLink onClick={handleRefresh} className="logo">
        F&F
      </StyledLink>
      <RightContainer isFnCo={isFnCo} className={'profile'}>
        {showMenu && <Profile />}
      </RightContainer>
    </StyledHeader>
  );
};

export default HeaderV1;
